<template>
  <div id="personnelRequirement">
    <el-dialog
      :title="personnelRequirementFormTitle"
      width="1200px"
      :visible.sync="personnelRequirementDialogVisible"
      :close-on-click-modal="false"
      @close="personnelRequirementDialogClose"
    >
      <el-form
        ref="personnelRequirementFormRef"
        :model="personnelRequirementForm"
        :rules="personnelRequirementFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="部门" prop="dept">
              <el-input v-model="personnelRequirementForm.dept" placeholder="请输入部门" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="personnelRequirementFormTitle !== '人员需求申请表详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="personnelRequirementDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="post"
              title="招聘岗位"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="recruitsNumber"
              title="招聘人数"
              :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
            />
            <vxe-table-column
              field="age"
              title="年龄要求"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="education"
              title="学历要求"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="wages"
              title="工资待遇"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="workingTime"
              title="用工时间"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column v-if="personnelRequirementFormTitle !== '人员需求申请表详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="招聘理由" prop="reason">
              <el-input
                v-model="personnelRequirementForm.reason"
                placeholder="请输入招聘理由"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="部门经理签字" prop="deptManagerSignature">
              <el-input v-model="personnelRequirementForm.deptManagerSignature" placeholder="请输入部门经理签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="deptManagerSignatureDate">
              <el-date-picker v-model="personnelRequirementForm.deptManagerSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="总经理批示" prop="generalManagerInstruction">
              <el-input
                v-model="personnelRequirementForm.generalManagerInstruction"
                placeholder="请输入总经理批示"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="generalManagerSignatureDate">
              <el-date-picker v-model="personnelRequirementForm.generalManagerSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="personnelRequirementDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="personnelRequirementFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="personnelRequirementPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="dept" label="部门" />
      <el-table-column prop="reason" label="招聘理由" />
      <el-table-column prop="deptManagerSignature" label="部门经理签字" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.deptManagerSignatureDate">{{ scope.row.deptManagerSignatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="generalManagerInstruction" label="总经理批示" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.generalManagerSignatureDate">{{ scope.row.generalManagerSignatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="personnelRequirementPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addPersonnelRequirement, deletePersonnelRequirement, updatePersonnelRequirement, selectPersonnelRequirementInfo, selectPersonnelRequirementList } from '@/api/universal/personnelRequirement'

export default {
  data () {
    return {
      personnelRequirementDialogVisible: false,
      personnelRequirementFormTitle: '',
      personnelRequirementForm: {
        id: '',
        dept: '',
        reason: '',
        deptManagerSignature: '',
        deptManagerSignatureDate: '',
        generalManagerInstruction: '',
        generalManagerSignatureDate: '',
        personnelJson: ''
      },
      personnelRequirementFormRules: {
        dept: [{ required: true, message: '部门不能为空', trigger: ['blur', 'change']}]
      },
      personnelRequirementPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dept: ''
      },
      personnelRequirementDetailList: []
    }
  },
  created () {
    selectPersonnelRequirementList(this.searchForm).then(res => {
      this.personnelRequirementPage = res
    })
  },
  methods: {
    personnelRequirementDialogClose () {
      this.$refs.personnelRequirementFormRef.resetFields()
      this.personnelRequirementDetailList = []
    },
    personnelRequirementFormSubmit () {
      if (this.personnelRequirementFormTitle === '人员需求申请表详情') {
        this.personnelRequirementDialogVisible = false
        return
      }
      this.$refs.personnelRequirementFormRef.validate(async valid => {
        if (valid) {
          this.personnelRequirementForm.personnelJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.personnelRequirementFormTitle === '新增人员需求申请表') {
            await addPersonnelRequirement(this.personnelRequirementForm)
          } else if (this.personnelRequirementFormTitle === '修改人员需求申请表') {
            await updatePersonnelRequirement(this.personnelRequirementForm)
          }
          this.personnelRequirementPage = await selectPersonnelRequirementList(this.searchForm)
          this.personnelRequirementDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.personnelRequirementFormTitle = '新增人员需求申请表'
      this.personnelRequirementDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deletePersonnelRequirement(row.id)
        if (this.personnelRequirementPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.personnelRequirementPage = await selectPersonnelRequirementList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.personnelRequirementFormTitle = '修改人员需求申请表'
      this.personnelRequirementDialogVisible = true
      this.selectPersonnelRequirementInfoById(row.id)
    },
    handleInfo (index, row) {
      this.personnelRequirementFormTitle = '人员需求申请表详情'
      this.personnelRequirementDialogVisible = true
      this.selectPersonnelRequirementInfoById(row.id)
    },
    selectPersonnelRequirementInfoById (id) {
      selectPersonnelRequirementInfo(id).then(res => {
        this.personnelRequirementForm.id = res.id
        this.personnelRequirementForm.dept = res.dept
        this.personnelRequirementForm.reason = res.reason
        this.personnelRequirementForm.deptManagerSignature = res.deptManagerSignature
        this.personnelRequirementForm.deptManagerSignatureDate = res.deptManagerSignatureDate
        this.personnelRequirementForm.generalManagerInstruction = res.generalManagerInstruction
        this.personnelRequirementForm.generalManagerSignatureDate = res.generalManagerSignatureDate
        this.personnelRequirementDetailList = res.personnelList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectPersonnelRequirementList(this.searchForm).then(res => {
        this.personnelRequirementPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectPersonnelRequirementList(this.searchForm).then(res => {
        this.personnelRequirementPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectPersonnelRequirementList(this.searchForm).then(res => {
        this.personnelRequirementPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>

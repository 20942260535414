import axios from '@/common/axios'
import qs from 'qs'

export function addPersonnelRequirement (data) {
  return axios({
    method: 'post',
    url: '/universal/personnel/add',
    data: qs.stringify(data)
  })
}

export function deletePersonnelRequirement (id) {
  return axios({
    method: 'delete',
    url: '/universal/personnel/delete/' + id
  })
}

export function updatePersonnelRequirement (data) {
  return axios({
    method: 'put',
    url: '/universal/personnel/update',
    data: qs.stringify(data)
  })
}

export function selectPersonnelRequirementInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/personnel/info/' + id
  })
}

export function selectPersonnelRequirementList (query) {
  return axios({
    method: 'get',
    url: '/universal/personnel/list',
    params: query
  })
}
